<template style="background: #e5e5e5">
  <v-container fluid>
    <v-row class="px-3">
      <v-col cols="12">
        <h1 class="secondary--text">Movimentação Cadastral</h1>
      </v-col>
      <v-col cols="12" align="start">
        <p class="title">Detalhes da movimentação</p>
      </v-col>
      <v-row>
        <v-col cols="12" class="d-flex align-center justify-end">
          <LegendsChip :legends="legends" />
        </v-col>
      </v-row>
    </v-row>
    <v-row v-if="((movementRecord && movementRecord.beneficiaryType === 'HOLDER') && (movementRecord.movementRecordParentId || movementRecord.returnCriticizedDate || movementRecord.updatedMovementDate))" class="ma-0 pa-0" justify="center">
      <v-col cols="12" xl="12" class="pb-0">
        <v-card class="py-5">
          <v-row justify="center">
            <v-col cols="12" md="1" :align="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 'center' : 'end'" lg="auto" :class="verifyLogMovements() ? 'pr-0' : 'pt-5 pr-0'">
              <v-icon color="info" :size="verifyLogMovements() ? '25' : '35'">
                fas fa-info-circle
              </v-icon>
            </v-col>
            <v-col cols="12" md="11" lg="auto" :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md ? 'px-5' : ''">
              <h3 v-if="movementRecord && (movementRecord.returnCriticizedDate !== null && movementRecord.movementRecordParentId !== null)" class="font-weight-regular">
                <span>Este registro é uma correção da movimentação criticada em </span><span class="font-weight-bold">{{ `${movementRecord ? formatter.formatDateTimeZoneWithHours(movementRecord&& movementRecord.returnCriticizedDate) : ''}.` }}</span><span> Para ver detalhes da movimentação anterior<a class="info--text" @click="redirectMovementCriticized()"> clique aqui.</a></span>
              </h3>
              <h3 v-if="movementRecord && movementRecord.updatedMovementDate !== null" class="font-weight-regular">
                <span>Este registro foi alterado devido a críticas informadas pela operadora em</span><span class="font-weight-bold">  {{ `${movementRecord ? formatter.formatDateTimeZoneWithHours(movementRecord.updatedMovementDate) : ''}.` }}</span><span>  Para ver a movimentação atualizada<a class="info--text" @click="redirectMovementUpdated()"> clique aqui.</a></span>
              </h3>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <div class="px-3 mt-5">
      <v-row>
        <v-col cols="12" align="start">
          <p class="title">Informações do Titular</p>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3">
          <label class="label">Grupo Financeiro</label>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-text-field
                  :class="verifyFieldInArray('financialGroupId') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                  :loading="loadingFinancialGroups"
                  :value="financialGroupName"
                  :placeholder="placeholderNoData"
                  color="textPrimary"
                  outlined
                  readonly
                />
              </div>
            </template>
            <span>{{ financialGroupName }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3">
          <label class="label">CPF</label>
          <v-text-field
            :class="verifyFieldInArray('holderDocumentNumber') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="formatter.formatCpf(movementRecord.holderDocumentNumber)"
            :placeholder="placeholderNoData"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3">
          <label class="label">Nome do Titular</label>
          <v-text-field
            :class="verifyFieldInArray('holderName') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="formatter.formatToTitleCase(movementRecord.holderName)"
            :placeholder="placeholderNoData"
            outlined
            color="textPrimary"
            readonly
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3">
          <label class="label">Centro de Custo</label>
          <v-row
            no-gutters>
            <v-col
              cols="3">
              <v-text-field
                :class="verifyFieldInArray('taxAllocationCode') ? 'rounded-r-0 alterationColorText alterationColorBorder mt-2' : 'rounded-r-0 mt-2'"
                :value="movementRecord.taxAllocationCode"
                color="textPrimary"
                outlined
                readonly
              />
            </v-col>
            <v-col
              cols="9">
              <v-text-field
                :class="verifyFieldInArray('taxAllocationDescription') ? 'rounded-l-0 alterationColorText alterationColorBorder mt-2' : 'rounded-l-0 mt-2'"
                :value="movementRecord.taxAllocationDescription"
                :placeholder="placeholderNoData"
                color="textPrimary"
                outlined
                readonly
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Departamento</label>
          <v-text-field
            :class="verifyFieldInArray('costCenterDepartment') ? 'alterationColorText alterationColorBorder mt-2 ' : 'mt-2'"
            :value="department"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <template v-if="isFreeMovement">
          <v-col cols="12" xs="12" sm="12" md="3">
            <label class="label">Matrícula*</label>
            <v-text-field
              :class="verifyFieldInArray('registrationPlate') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
              :value="movementRecord.registrationPlate"
              :placeholder="placeholderNoData"
              outlined
              color="textPrimary"
              readonly
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="3">
            <label class="label">Complemento da Matrícula</label>
            <v-text-field
              :class="verifyFieldInArray('registrationComplement') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
              :value="movementRecord.registrationComplement"
              :placeholder="placeholderNoData"
              outlined
              color="textPrimary"
              readonly
            />
          </v-col>
        </template>
        <v-col cols="12" xs="12" sm="12" md="3">
          <label class="label">Data do Evento</label>
          <v-text-field
            :class="verifyFieldInArray('eventDate') && movementRecord.beneficiaryType === 'HOLDER' ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="
              movementRecord
              && movementRecord.beneficiaryType !== 'DEPENDENT'
              ? movementRecord.eventDate
                ? formatter.formatDate(movementRecord.eventDate)
                : null
              : holder
                && holder.eventDate
                  ? formatter.formatDate(holder.eventDate)
                  : null"
            :placeholder="placeholderNoData"
            outlined
            color="textPrimary"
            readonly
          />
        </v-col>
      </v-row>

      <v-row class="mt-10" v-if="(movementRecord.beneficiaryType === 'HOLDER')">
        <v-col cols="12" md="6" align="start">
          <p class="title">Informações do Plano</p>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" xs="12" sm="12" md="3">
          <label class="label">Contrato/Apólice</label>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-text-field
                  :loading="loadingContracts"
                  :class="verifyFieldInArray('policy') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                  :value="contractName"
                  :placeholder="placeholderNoData"
                  color="textPrimary"
                  outlined
                  readonly
                />
              </div>
            </template>
            <span>{{ contractName }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3">
          <label class="label">Sub-Contrato</label>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-text-field
                  :loading="loadingSubContracts"
                  :class="verifyFieldInArray('subContract') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                  :value="subContractName"
                  :placeholder="placeholderNoData"
                  color="textPrimary"
                  outlined
                  readonly
                  :success-messages="previousSubContractName"
                />
              </div>
            </template>
            <span>{{ subContractName }}</span>
          </v-tooltip>
        </v-col>
        <v-col
          cols="12" xs="12" sm="12" md="3">
          <label
            class="label">
            Unidade
          </label>
          <v-row
            no-gutters>
            <v-col
              cols="3">
              <v-text-field
                :value="unitCode"
                color="textPrimary"
                outlined
                readonly
                :class="verifyFieldInArray('unitId') ? 'rounded-r-0 alterationColorText alterationColorBorder mt-2' : 'rounded-r-0 mt-2'"
              />
            </v-col>
            <v-col
              cols="9">
              <v-text-field
                :value="unitDescription"
                color="textPrimary"
                outlined
                :placeholder="placeholderNoData"
                readonly
                :class="verifyFieldInArray('unitId') ? 'rounded-l-0 alterationColorText alterationColorBorder mt-2' : 'rounded-l-0 mt-2'"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3">
          <label class="label">Elegibilidade</label>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-text-field
                  :loading="loadingEligibilities"
                  :class="verifyFieldInArray('contractEligibilityId') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                  :value="eligibilityName"
                  :placeholder="placeholderNoData"
                  color="textPrimary"
                  outlined
                  readonly
                />
              </div>
            </template>
            <span>{{ eligibilityName }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3">
          <v-container>
            <v-row
              class="d-flex justify-space-between">
              <label class="label">Plano</label>

              <v-tooltip bottom color="primary" v-if="coverages.length !== 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-on="on"
                    v-bind="attrs"
                    color="textPrimary"
                    dark
                    @click="openCoverageDetailsModal()">
                    fas fa-info-circle
                  </v-icon>
                </template>
                <span>
                  Vizualizar coberturas
                </span>
              </v-tooltip>
            </v-row>
          </v-container>

          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-text-field
                  :loading="loadingPlans"
                  :class="verifyFieldInArray('plan') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                  :value="planName"
                  :placeholder="placeholderNoData"
                  color="textPrimary"
                  outlined
                  readonly
                  :success-messages="previousPlanName"
                />
              </div>
            </template>
            <span>{{ planName }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3">
          <label class="label">Número do Cartão</label>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-text-field
                  :class="verifyFieldInArray('beneficiaryCard') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                  :value="beneficiaryCard && movementRecord.beneficiaryType === 'HOLDER' ? beneficiaryCard : null"
                  :placeholder="placeholderNoData"
                  color="textPrimary"
                  outlined
                  readonly
                />
              </div>
            </template>
            <span>{{ beneficiaryCard }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3">
          <label class="label">Início de Vigência</label>
          <v-text-field
            class="mt-2"
            :value="formatter.formatDate(holder && movementRecord && movementRecord.beneficiaryType === 'DEPENDENT' ? holder.startDate : movementRecord.startDate)"
            :class="verifyFieldInArray('startDate') && movementRecord.beneficiaryType === 'HOLDER' ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3">
          <label class="label">Fim de Vigência</label>
          <v-text-field
            class="mt-2"
            :value="
              movementRecord
              && movementRecord.beneficiaryType === 'DEPENDENT'
                ? holder && holder.endDate
                  ? formatter.formatDate(holder.endDate) : null
                : movementRecord && movementRecord.endDate
                  ? formatter.formatDate(movementRecord.endDate) : null
            "
            :class="verifyFieldInArray('endDate') && movementRecord.beneficiaryType === 'HOLDER' ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" v-if="movementRecord.beneficiaryType !== 'DEPENDENT'">
          <label class="label">Início de Vigência Efetiva</label>
          <v-text-field
            class="mt-2"
            :value="formatter.formatDate(movementRecord.sendStartDate)"
            :class="verifyFieldInArray('sendStartDate') && movementRecord.beneficiaryType === 'HOLDER' ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" v-if="movementRecord.beneficiaryType !== 'DEPENDENT'">
          <label class="label">Fim de Vigência Efetiva</label>
          <v-text-field
            class="mt-2"
            :value="formatter.formatDate(movementRecord.sendEndDate)"
            :class="verifyFieldInArray('sendEndDate') && movementRecord.beneficiaryType === 'HOLDER' ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3">
          <label class="label">Motivo de Alteração</label>
          <v-text-field
            class="mt-2"
            :value="movementRecord && movementRecord.planChangeReasonCode ?capturePlanReasonCode(movementRecord.planChangeReasonCode) : null"
            :class="verifyFieldInArray('planChangeReasonCode') && movementRecord.beneficiaryType === 'HOLDER' ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Motivo de Exclusão</label>
          <v-text-field
            :class="verifyFieldInArray('exclusionReasonCode') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="
              movementRecord
              && movementRecord.beneficiaryType !== 'DEPENDENT'
              ? movementRecord.exclusionReasonCode
                ? verifyCancellationReasonCodeAndReturnName(movementRecord.exclusionReasonCode)
                : null
              : holder
                && holder.cancellationReason
                  ? holder.cancellationReason
                  : null"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3">
          <label class="label">Contributário</label>
          <v-text-field
            class="mt-2"
            :value="movementRecord.contributor === true ? 'Sim' : 'Não'"
            :class="verifyFieldInArray('contributor') && movementRecord.beneficiaryType === 'HOLDER' ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3">
          <label class="label">Extensão de Plano</label>
          <v-text-field
            class="mt-2"
            :value="movementRecord.planExtension != null && movementRecord.planExtension === true ? 'Sim' : movementRecord.planExtension != null && movementRecord.planExtension === false ? 'Não' : ''"
            :class="verifyFieldInArray('planExtension') && movementRecord.beneficiaryType === 'HOLDER' ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3">
          <label class="label">Período de Contribuição, em meses</label>
          <v-text-field
            class="mt-2"
            :value="movementRecord.contributionPeriod"
            :class="verifyFieldInArray('contributionPeriod') && movementRecord.beneficiaryType === 'HOLDER' ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" v-if="movementRecord.beneficiaryType === 'HOLDER'">
          <label class="label">Carência</label>
          <v-text-field
            class="mt-2"
            :value="movementRecord.hasGracePeriod !== null ? movementRecord.hasGracePeriod === true ? 'Sim' : 'Não' : null"
            :class="verifyFieldInArray('hasGracePeriod') && movementRecord.beneficiaryType === 'HOLDER' ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12">
          <v-alert
            :value="verifyFieldInArray('plan')"
            transition="fade-transition"
            class="py-4"
            icon="mdi-alert"
            color="textPrimary"
            dense
            outlined
          >
            <strong>Motivo da alteração do plano:</strong> {{ verifyFieldInArray('plan') ? `${capturePlanReasonCode(movementRecord.planChangeReasonCode)}` : null }}
          </v-alert>
        </v-col>
      </v-row>

      <v-row class="mt-10" v-if="movementRecord.beneficiaryType === 'HOLDER'">
        <v-col cols="12" md="6" align="start">
          <p class="title">Informações Complementares do Beneficiário</p>
        </v-col>
      </v-row>
      <v-row class="mt-0" v-if="movementRecord.beneficiaryType === 'HOLDER'">
        <v-col cols="12" md="3">
          <label class="label">Data de nascimento</label>
          <v-text-field
            class="mt-2"
            :value="formatter.formatDate(movementRecord.birthDate)"
            :class="verifyFieldInArray('birthDate') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Estado Civil</label>
          <v-text-field
            :class="verifyFieldInArray('maritalStatus') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="maritalStatusName"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Gênero</label>
          <v-text-field
            :class="verifyFieldInArray('gender') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="genderName"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Nome da mãe</label>
          <v-text-field
            :class="verifyFieldInArray('motherName') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="formatter.formatToTitleCase(movementRecord.motherName)"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">CNS</label>
          <v-text-field
            :class="verifyFieldInArray('cns') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="movementRecord.cns"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Nome da empresa</label>
          <v-text-field
            :class="verifyFieldInArray('companyName') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="formatter.formatToTitleCase(movementRecord.companyName)"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">CNPJ da empresa</label>
          <v-text-field
            :class="verifyFieldInArray('companyDocumentNumber') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="movementRecord.companyDocumentNumber"
            v-mask="'##.###.###/####-##'"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Declaração de Nascido Vivo (DNV)</label>
          <v-text-field
            :class="verifyFieldInArray('dnv') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="movementRecord.dnv"
            :placeholder="placeholderNoData"
            v-mask="'##-########-#'"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Vínculo empregatício</label>
          <v-text-field
            :class="verifyFieldInArray('employmentRelationship') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="employmentRelationshipName"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Carteira de Trabalho</label>
          <v-text-field
            :class="verifyFieldInArray('professionalCardNumber') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="movementRecord.professionalCardNumber"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">PIS / PASEP</label>
          <v-text-field
            :class="verifyFieldInArray('pisPasep') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="movementRecord.pisPasep"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
            v-mask="'###.#####.##-#'"
          />
        </v-col>
        <template v-if="!isFreeMovement">
          <v-col cols="12" md="3">
            <label class="label">Matrícula</label>
            <v-text-field
              :class="verifyFieldInArray('registrationPlate') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
              maxlength="50"
              :value="movementRecord.registrationPlate"
              :placeholder="placeholderNoData"
              color="textPrimary"
              outlined
              readonly
              :success-messages="previousRegistrationPlate !== null ? `Matrícula Anterior: ${previousRegistrationPlate}` : null"
            />
          </v-col>
          <v-col cols="12" md="3">
            <label class="label">Complemento de Matrícula</label>
            <v-text-field
              :class="verifyFieldInArray('registrationComplement') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
              maxlength="50"
              :value="movementRecord.registrationComplement"
              :placeholder="placeholderNoData"
              color="textPrimary"
              outlined
              readonly
              :success-messages="previousRegistrationComplement !== null ? `Complemento de Matrícula Anterior: ${previousRegistrationComplement}` : null"
            />
          </v-col>
        </template>
        <v-col cols="12" md="3">
          <label class="label">Cargo</label>
          <v-text-field
            :class="verifyFieldInArray('insuredPosition') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="movementRecord.insuredPosition"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>        
        <v-col cols="12" md="3">
          <label class="label">Nacionalidade</label>
          <v-text-field
            :class="verifyFieldInArray('nationality') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="movementRecord.nationality"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Estado de nascimento</label>
          <v-text-field
            class="mt-2"
            :value="movementRecord.stateBirth"
            :class="verifyFieldInArray('stateBirth') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Peso(kg)</label>
          <v-text-field
            :class="verifyFieldInArray('weight') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="movementRecord.weight"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Altura(cm)</label>
          <v-text-field
            :class="verifyFieldInArray('height') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="movementRecord.height"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3" v-if="movementRecord.nationality !== 'BRASILEIRA'">
          <label class="label">RNE/RNM</label>
          <v-text-field
            :class="verifyFieldInArray('rne') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="movementRecord.rne"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12">
          <label class="title">Dados de Contato</label>
          <v-row class="my-5">
            <v-col cols="12" md="3">
              <label class="label">Telefone Residencial</label>
              <v-text-field
                :class="verifyFieldInArray('homephoneDdd' + ' ' + 'homephoneNumber') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                :value="(movementRecord.homephoneDDD && movementRecord.homephoneNumber) ? movementRecord.homephoneDDD + movementRecord.homephoneNumber : null"
                :placeholder="placeholderNoData"
                color="textPrimary"
                outlined
                readonly
                v-mask="'(##) ####-####'"
              />
            </v-col>
            <v-col cols="12" md="3">
              <label class="label">Celular</label>
              <v-text-field
                :class="verifyFieldInArray('cellphoneDdd' + ' ' + 'cellphoneNumber') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                :value="(movementRecord.cellphoneDDD && movementRecord.cellphoneNumber) ? movementRecord.cellphoneDDD + movementRecord.cellphoneNumber : null"
                :placeholder="placeholderNoData"
                color="textPrimary"
                outlined
                readonly
                v-mask="'(##) #####-####'"
              />
            </v-col>
            <v-col cols="12" md="3">
              <label class="label">E-Mail</label>
              <v-text-field
                :class="verifyFieldInArray('email') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                :value="movementRecord.email"
                :placeholder="placeholderNoData"
                color="textPrimary"
                outlined
                readonly
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <label class="title">Identidade</label>
          <v-row class="my-5">
            <v-col cols="3">
              <label class="label">Natureza</label>
              <v-text-field
                :class="verifyFieldInArray('natureIdentifyDocument') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                :value="movementRecord.natureIdentifyDocument"
                :placeholder="placeholderNoData"
                color="textPrimary"
                outlined
                readonly
              />
            </v-col>
            <v-col cols="3">
              <label class="label">Documento</label>
              <v-text-field
                :class="verifyFieldInArray('rgDocumentIdentify') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                :value="movementRecord.rgDocumentIdentify"
                :placeholder="placeholderNoData"
                color="textPrimary"
                outlined
                readonly
                v-mask="'NNNNNNNNNNNNNNN'"
              />
            </v-col>
            <v-col cols="12" md="3">
              <label class="label">Data de Expedição</label>
              <v-text-field
                :class="verifyFieldInArray('rgDocumentIssueDate') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                :value="formatter.formatDate(movementRecord.rgDocumentIssueDate)"
                :placeholder="placeholderNoData"
                color="textPrimary"
                outlined
                readonly
              />
            </v-col>
            <v-col cols="3">
              <label class="label">Orgão Emissor</label>
              <v-text-field
                :class="verifyFieldInArray('rgDocumentIssuingBody') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                :value="movementRecord.rgDocumentIssuingBody"
                :placeholder="placeholderNoData"
                color="textPrimary"
                outlined
                readonly
              />
            </v-col>
          </v-row>
          <v-row class="my-5">
            <v-col cols="3">
              <label class="label">UF Emissor</label>
              <v-text-field
                :class="verifyFieldInArray('documentStateIssuer') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                :value="movementRecord.documentStateIssuer"
                :placeholder="placeholderNoData"
                color="textPrimary"
                outlined
                readonly
              />
            </v-col>
            <v-col cols="3">
              <label class="label">País Emissor</label>
              <v-text-field
                :class="verifyFieldInArray('documentCountryIssue') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
                :value="movementRecord.documentCountryIssue"
                :placeholder="placeholderNoData"
                color="textPrimary"
                outlined
                readonly
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="mt-10" v-if="movementRecord.beneficiaryType === 'HOLDER'">
        <v-col cols="12" md="6" align="start">
          <p class="title">Informações de Endereço</p>
        </v-col>
      </v-row>
      <v-row class="mt-0" v-if="movementRecord.beneficiaryType === 'HOLDER'">
        <v-col cols="12" md="3">
          <label class="label">CEP</label>
          <v-text-field
            :class="verifyFieldInArray('addressZip') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="movementRecord.addressZip"
            :placeholder="placeholderNoData"
            color="textPrimary"
            v-mask="'#####-###'"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Endereço</label>
          <v-text-field
            :class="verifyFieldInArray('address') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="formatter.formatToTitleCase(movementRecord.address)"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Número</label>
          <v-text-field
            :class="verifyFieldInArray('addressNumber') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="movementRecord.addressNumber"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Complemento</label>
          <v-text-field
            :class="verifyFieldInArray('addressComplement') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="formatter.formatToTitleCase(movementRecord.addressComplement)"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Bairro</label>
          <v-text-field
            :class="verifyFieldInArray('addressNeighborhood') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="formatter.formatToTitleCase(movementRecord.addressNeighborhood)"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Município</label>
          <v-text-field
            :class="verifyFieldInArray('addressCity') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="formatter.formatToTitleCase(movementRecord.addressCity)"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Estado</label>
          <v-text-field
            :class="verifyFieldInArray('addressState') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="movementRecord.addressState"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Tipo do logradouro</label>
          <v-text-field
            :class="verifyFieldInArray('addressType') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="formatter.formatToTitleCase(movementRecord.addressType)"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
      </v-row>

      <v-row class="mt-10" v-if="movementRecord.beneficiaryType === 'HOLDER'">
        <v-col cols="12" md="6" align="start">
          <p class="title">Dados Bancários</p>
        </v-col>
      </v-row>
      <v-row class="mt-0" v-if="movementRecord.beneficiaryType === 'HOLDER'">
        <v-col cols="12" md="3">
          <label class="label">Banco</label>
          <v-text-field
            :class="verifyFieldInArray('bank') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="bankName"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Agência</label>
          <v-text-field
            :class="verifyFieldInArray('branchBank') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="movementRecord.branchBank"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">DV</label>
          <v-text-field
            :class="verifyFieldInArray('branchBankCheckDigit') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="movementRecord.branchBankCheckDigit"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Nº da Conta</label>
          <v-text-field
            :class="verifyFieldInArray('bankAccount') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="movementRecord.bankAccount"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">DV</label>
          <v-text-field
            :class="verifyFieldInArray('bankAccountCheckDigit') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :value="movementRecord.bankAccountCheckDigit"
            :placeholder="placeholderNoData"
            color="textPrimary"
            outlined
            readonly
          />
        </v-col>
      </v-row>

      <template v-if="(movementRecord && movementRecord.beneficiaryType === 'HOLDER')">
        <UploadDocuments
          class="pt-0 ml-2"
          :showEditAndSaveButton="false"
          :movementRecord="movementRecord"
        />
      </template>
    </div>

    <div v-if="dependents && dependents.length > 0" class="px-3">
      <DetailDependents
        :propsDependents="dependents"
        :propsGenders="genders"
        :propsMaritalStatus="maritalStatus"
        :propsChangedFields="changedFields"
        :propsCancellationReasons="exclusionReasons"
      />
    </div>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <div>Carregando...</div>
      </v-progress-circular>
    </v-overlay>

    <DefaultModal
      ref='DefaultModal'
      :title="'Coberturas do Plano'"
      :defaultWidth="700">
      <template
        slot="section">
        <div
          style="max-height: 400px; overflow-y: auto">
          <v-container
            fluid>
            <v-row
              no-gutters>
              <v-col class="pl-2 wineLabel--text font-weight-bold">Cobertura</v-col>
              <v-col class="pl-5 wineLabel--text font-weight-bold">Início da Cobertura</v-col>
              <v-col class="pl-5 wineLabel--text font-weight-bold">Fim da Cobertura</v-col>
              <v-col
                cols="12">
                <template
                  v-for="(coverage, i) in coverages">
                  <v-sheet
                    outlined
                    class="pl-2 py-1"
                    :style="i != coverages.length-1
                      ? 'border-bottom: 0px !important;'
                      : null">
                      <v-row>
                        <v-col>
                          {{ coverage.coverage_id.name }}
                        </v-col>
                        <v-col>
                          {{
                            coverage.coverage_id.additive_start
                            ? moment(coverage.coverage_id.additive_start, 'YYYY-MM-DD').format('DD/MM/YYYY')
                            : '—'
                          }}
                        </v-col>
                        <v-col>
                          {{
                            coverage.coverage_id.additive_end
                            ? moment(coverage.coverage_id.additive_end, 'YYYY-MM-DD').format('DD/MM/YYYY')
                            : '—'
                          }}
                        </v-col>
                      </v-row>
                  </v-sheet>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </template>
    </DefaultModal>

    <v-footer class="my-10">
      <v-row class="d-flex justify-end">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            x-large
            block
            outlined
            color="textPrimary"
            @click="onClickBack()"
          >
            Voltar
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import FinancialGroupService from '@/services-http/contract/FinancialGroupService';
import ContractService from '@/services-http/contract/ContractService';
import ContractEligibilityService from '@/services-http/contract/ContractEligibilityService';
import Product2Service from '@/services-http/contract/Product2Service';
import ContractProductService from '@/services-http/contract/ContractProductService';
import GenderService from '@/services-http/contract/GenderService';
import MaritalStatusService from '@/services-http/contract/MaritalStatusService';
import EmploymentRelationshipService from '@/services-http/sdi/EmploymentRelationshipService';
import BankService from '@/services-http/sdi/BankService';
import DetailDependents from '@/components/DetailMovements/DetailDependents.vue';
import Rule from '@/shared/validators/formRules';
import CancelationReasonService from '@/services-http/odoo/CancelationReasonService';
import PlanChangeReasonService from '@/services-http/sdi/PlanChangeReasonService';
import UploadDocuments from '@/components/Beneficiary/Documents/UploadDocuments.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import LegendsChip from '@/components/Legends/LegendsChip.vue';
import BeneficiaryContractedPlanService from '@/services-http/contract/BeneficiaryContractedPlanService';
import DefaultModal from '@/components/Modals/DefaultModal/DefaultModal.vue';
import moment from 'moment';

export default {
  components: { DetailDependents, UploadDocuments, LegendsChip, DefaultModal },
  data: () => ({
    moment: moment,
    overlay: false,
    placeholderNoData: 'Não informado',
    coverages: [],
    dependents: [],
    movementRecord: {},
    financialGroups: [],
    contracts: [],
    subContracts: [],
    eligibilities: [],
    banks: [],
    plans: [],
    department: '',
    financialGroupName: '',
    contractName: '',
    subContractName: '',
    eligibilityName: '',
    planName: '',
    beneficiaryCard: '',
    bankName: '',
    genders: [],
    maritalStatus: [],
    employmentRelationships: [],
    unitCode: '',
    unitDescription: '',
    genderName: '',
    maritalStatusName: '',
    employmentRelationshipName: '',
    loadingFinancialGroups: false,
    loadingContracts: false,
    loadingSubContracts: false,
    loadingEligibilities: false,
    loadingPlans: false,
    isRHProtegido: false,

    previousSubContract: '',
    previousSubContractName: '',
    previousPlan: '',
    previousPlanName: '',
    previousRegistrationPlate: '',
    previousRegistrationComplement: '',
    changedFields: [],
    exclusionReasons: [],
    planChangeReasons: [],
    isFreeMovement: false,
    seTemSo1: false,
    dataResponse: null,
    holder: null,
    beneficiaryContractedPlanService: null,
    movementRecordChange: null,
  }),

  mixins: [
    VerifyRoutesMixin,
  ],

  async mounted() {
    if (this.$route.query.freeMovement && (this.$route.query.freeMovement === 'true' || this.$route.query.freeMovement === true)) {
      this.isFreeMovement = true;
    }
    await this.loadDataInfo();
    this.loadCoverageDetails();
  },

  watch: {
    dataResponse(newValue) {
      if (newValue) {
        if (this.isRHProtegido) {
          this.$router.push({ name: 'MovementDetail', query: { id: this.dataResponse, freeMovement: this.isFreeMovement, isRHProtegido: `${this.isRHProtegido}` } }).then(() => { this.$router.go(); });
        } else {
          this.$router.push({ name: 'MovementDetail', query: { id: this.dataResponse, freeMovement: this.isFreeMovement } }).then(() => { this.$router.go(); });
        }
      }
    },
  },

  computed: {
    legends() {
      return [
        {
          color: '#f4f4f4',
          icon: 'mdi-circle',
          iconColor: 'rgb(25, 0, 255)',
          text: 'Sofreu alteração',
        },
      ];
    },
  },

  methods: {
    async loadDataInfo() {
      this.loadMovementRecord();
      await this.loadMovementRecordByIdChanges(this.$route.query.id);
      await this.getContractedPlanData();
      this.loadMovementRecord();
      this.loadBanks();
      this.loadGenders();
      this.loadMaritalStatus();
      this.loadEmploymentRelationship();
      this.loadPlanChangeReasons();
      this.loadFinancialGroups();
      this.loadContractsByFinancialGroupId();
      await this.loadSubContractsByContractId();
      this.loadEligibilitiesByContractIdAndSubContractId();
      this.loadPlansById();
      this.loadPreviousPlanById();
      this.loadUnit();
    },
    loadCoverageDetails() {
      this.coverages = [];

      if (
        !this.movementRecord.contractId
        || !this.movementRecord.plan
        || isNaN(this.movementRecord.plan)
      ) return;

      this.contractService.FindActiveContractPlanCoverage(
          this.movementRecord.contractId,
          this.movementRecord.plan
        )
        .then((response) => response.data.content)
        .then((result) => {
          const coverages = result.filter((obj, index, self) =>
            index === self.findIndex((t) => t.coverage_id.id === obj.coverage_id.id)
          );

          this.coverages = coverages;
        })
        .catch((err) => {
          this.coverages = [];
        });
    },
    openCoverageDetailsModal() {
      this.$refs.DefaultModal.open();
    },
    loadUnit() {
      const movementRecordSession = JSON.parse(sessionStorage.getItem('movementRecord'));

      this.unitId = movementRecordSession.unitId;
      this.unitCode = movementRecordSession.unitCode;
      this.unitDescription = movementRecordSession.unitDescription;
      this.department = movementRecordSession.costCenterDepartment;
    },
    async getContractedPlanData(){
      if (Object.keys(this.movementRecord).length === 0) return;

      if (this.movementRecord.contractedPlanId) {
        await this.beneficiaryContractedPlanService.FindById(`${this.movementRecord.contractedPlanId}/contracted_plan_data`)
        .then((response) => {
          if (response && response.data) {
            this.holder = response.data;
          }
        })
        .catch((err) => {})
      }
    },
    loadMovementRecord() {
      this.movementRecord = JSON.parse(sessionStorage.getItem('movementRecord'));
    },
    async loadMovementRecordByIdChanges(id) {
      if (id) {
        this.overlay = true;
        await this.movementRecordService.GetMovementRecordByIdChangedFields(id).then(async (response) => {
          if (response && response.data) {
            this.movementRecordChange = await response.data;
            this.previousPlan = Number(this.movementRecordChange.previousPlan);
            this.previousRegistrationPlate = this.movementRecordChange.previousRegistrationPlate;
            this.previousRegistrationComplement = this.movementRecordChange.previousRegistrationComplement;
            this.previousSubContract = this.movementRecordChange.previousSubContract;
            this.changedFields = this.movementRecordChange.changedFields;
            this.beneficiaryCard = this.movementRecordChange.beneficiaryCard;
            this.getBeneficiaryDependent();
            if (this.movementRecordChange && (this.movementRecordChange.movementType === 'DELETE' || this.movementRecordChange.movementType === 'DELETE_DEPENDENT')) {
              await this.loadCancelationReasons();
            }
          }
          this.overlay = false;
        }).catch(() => {
          this.overlay = false;
        });
      }
    },
    async loadPreviousPlanById() {
      if (this.previousPlan) {
        await this.product2Service.FindById(this.previousPlan).then((response) => {
          if (response && response.data) {
            if (this.previousPlan !== Number(this.movementRecord.plan)) {
              this.previousPlanName = `Plano Anterior: ${response.data.name} - ${response.data.code}`;
            }
          }
        });
      }
    },
    getBeneficiaryDependent() {
      this.movementRecordChange.dependents.forEach((element) => {
        if (element) {
          this.dependents.push(element);
        }
      });
    },
    async loadFinancialGroups() {
      this.loadingFinancialGroups = true;
      this.financialGroupService.FindAll().then(async (response) => {
        if (response && response.data) {
          this.financialGroups = await response.data;
          this.convertFinancialGroupIdToNameToDisplayInFields(this.financialGroups);
          this.loadingFinancialGroups = false;
        }
      }).catch(() => {
        this.loadingFinancialGroups = false;
      });
    },
    convertFinancialGroupIdToNameToDisplayInFields(financialGroups) {
      const result = financialGroups.filter((f) => f.id === Number(this.movementRecord.financialGroupId));
      if (result.length > 0) {
        this.financialGroupName = result[0].name;
      }
    },
    async loadContractsByFinancialGroupId() {
      if (this.movementRecord.financialGroupId) {
        this.loadingContracts = true;
        const query = `financialGroupIds=${this.movementRecord.financialGroupId}`;
        await this.contractService.FindAllByFilters(query).then((response) => {
          if (response && response.data) {
            this.contracts = response.data.filter((c) => c.subcontract === false);
            this.convertContractIdToNameToDisplayInFields(this.contracts);
            this.loadingContracts = false;
          }
        }).catch(() => {
          this.loadingContracts = false;
        });
      }
    },
    convertContractIdToNameToDisplayInFields(contracts) {
      const item = contracts.find((c) => Number(c.policy) === Number(this.movementRecord.policy));
      if (item) {
        this.contractName = `${(item.benefit_id && item.benefit_id.name ? item.benefit_id.name : item.benefit_id ? item.benefit_id : '-')} - ${(item.carrier_id && item.carrier_id.xipp_commercial_name ? item.carrier_id.xipp_commercial_name : '-')} - ${item.policyNumber ? item.policyNumber : '-'} - ${item.contract_owner_id && item.contract_owner_id.legal_name ? item.contract_owner_id.legal_name : '-'}`;
      }
    },
    async loadSubContractsByContractId() {
      if (this.movementRecord.policy) {
        this.loadingSubContracts = true;
        await this.contractService.FindByParentId(this.movementRecord.contractId).then((response) => {
          if (response && response.data) {
            this.subContracts = response.data.filter((c) => c.subcontract === true);
            this.convertSubContractIdToNameToDisplayInFields(this.subContracts);
          }
          this.loadingSubContracts = false;
        }).catch(() => {
          this.loadingSubContracts = false;
        });
      }
    },
    convertSubContractIdToNameToDisplayInFields(subContracts) {
      const item = subContracts.find((subContract) => Number(subContract.id) === Number(this.movementRecord.subContractId));
      if (item) {
        this.subContractName = (`${item.subcontract_number ? item.subcontract_number : '-'} - ${item.contract_owner_id && item.contract_owner_id.legal_name ? item.contract_owner_id.legal_name : '-'}`);
      }
    },
    async loadEligibilitiesByContractIdAndSubContractId() {
      if (this.movementRecord.policy && this.movementRecord.subContractId) {
        this.loadingEligibilities = true;
        const query = `${this.movementRecord.contractId}?subcontractId=${this.movementRecord.subContractId}`;
        await this.contractEligibilityService.FindAllByFilters(query).then((response) => {
          if (response && response.data && response.data) {
            response.data.forEach((element) => {
              this.eligibilities.push(element);
            });
            this.convertEligibilityIdToNameToDisplayInFieldsHolder();
          }
          this.loadingEligibilities = false;
        }).catch(() => {
          this.loadingEligibilities = false;
        });
      }
      await this.capturePreviousSubContract();
    },
    convertEligibilityIdToNameToDisplayInFieldsHolder() {
      const result = this.eligibilities.filter((e) => e.id === this.movementRecord.contractEligibilityId);
      if (result.length > 0) {
        this.eligibilityName = result[0].elegibility_id.name;
      }
    },
    async capturePreviousSubContract() {
      if (this.movementRecord.subContractId && this.movementRecord.subContractId !== null && this.previousSubContract !== null) {
        const subContractModified = this.subContracts.find((subContract) => subContract.subcontract_number === this.previousSubContract);
        if (subContractModified) {
          this.previousSubContractName = `Sub-Contrato Anterior: ${(subContractModified && subContractModified.benefit_id ? subContractModified.benefit_id : '-')} - ${(subContractModified.carrier_id && subContractModified.carrier_id.legal_name ? subContractModified.carrier_id.legal_name : '-')} - ${subContractModified.subcontract_number ? subContractModified.subcontract_number : '-'} - ${subContractModified.contract_owner_id && subContractModified.contract_owner_id.legal_name ? subContractModified.contract_owner_id.legal_name : '-'}`;
        }
      }
    },
    async loadPlansById() {
      if (this.movementRecord.plan) {
        await this.product2Service.FindById(this.movementRecord.plan).then((response) => {
          if (response && response.data) {
            const plans = [];
            plans.push(response.data);
            plans.forEach((element) => {
              // eslint-disable-next-line no-param-reassign
              this.plans.push({
                ...element,
                name: `${element.name} - ${element.code}`,
              });
            });
            this.loadingPlans = false;
            this.convertPlanIdToNameToDisplayInFieldsHolder();
          }
        }).catch(() => {
          this.loadingPlans = false;
        });
      }
    },
    convertPlanIdToNameToDisplayInFieldsHolder() {
      const result = this.plans.filter((p) => p.id === Number(this.movementRecord.plan));
      if (result.length > 0) {
        this.planName = result[0].name;
      }
    },
    async loadGenders() {
      await this.genderService.FindAll().then((response) => {
        if (response && response.data) {
          this.genders = response.data;
          this.convertGenderIdToNameToDisplayInFieldsHolder();
        }
      });
    },
    convertGenderIdToNameToDisplayInFieldsHolder() {
      const result = this.genders.filter((g) => g.id === Number(this.movementRecord.gender));
      if (result.length > 0) {
        this.genderName = result[0].description;
      }
    },
    async loadMaritalStatus() {
      await this.maritalStatusService.FindAll().then((response) => {
        if (response && response.data) {
          this.maritalStatus = response.data;
          this.convertMaritalStatusIdToNameToDisplayInFieldsHolder();
        }
      });
    },
    convertMaritalStatusIdToNameToDisplayInFieldsHolder() {
      const result = this.maritalStatus.filter((m) => m.id === this.movementRecord.maritalStatus);
      if (result.length > 0) {
        this.maritalStatusName = result[0].description;
      }
    },
    async loadEmploymentRelationship() {
      await this.employmentRelationshipService.FindAll().then((response) => {
        if (response && response.data) {
          this.employmentRelationships = response.data;
          this.convertEmploymentRelationshipsIdToNameToDisplayInFieldsHolder();
        }
      });
    },
    convertEmploymentRelationshipsIdToNameToDisplayInFieldsHolder() {
      const result = this.employmentRelationships.filter((e) => e.id === this.movementRecord.employmentRelationshipId);
      if (result.length > 0) {
        this.employmentRelationshipName = result[0].description;
      }
    },
    async loadBanks() {
      await this.bankService.FindAll().then((response) => {
        if (response && response.data && response.data.content) {
          let banks = response.data.content;
          banks = banks.map((bank) => ({
            id: bank.id ? bank.id : null,
            description: bank && bank.code ? `${bank.code} - ${bank.name}` : bank.name,
            code: bank.code ? bank.code : null,
          }));
          this.banks = banks;
          this.convertBankIdToNameToDisplayInFieldsHolder();
        }
      });
    },
    convertBankIdToNameToDisplayInFieldsHolder() {
      const result = this.banks.filter((b) => b.code === Number(this.movementRecord.bank));
      if (result.length > 0) {
        this.bankName = result[0].description;
      }
    },
    onClickBack() {
      this.$router.push({ name: 'Movement', query: { isRHProtegido: `${this.isRHProtegido}` } });
    },
    verifyFieldInArray(field) {
      const fieldName = field.split(' ');
      if (fieldName.length === 1) {
        return this.changedFields.some((element) => element === fieldName[0]);
      }
      return this.changedFields.some((element) => element === fieldName[0] || element === fieldName[1]);
    },
    async loadCancelationReasons() {
      this.cancelationReasonService.FindAll().then((response) => {
        if ((response && response.data) && (response.data.records && response.data.records.length > 0)) {
          this.exclusionReasons = response.data.records;
        }
        this.exclusionReasons.sort((a, b) => (a.name > b.name ? 1 : -1));
      });
    },
    verifyCancellationReasonCodeAndReturnName(code) {
      let reason = 'Não informado';
      if (code && (this.exclusionReasons && this.exclusionReasons.length > 0)) {
        const exclusionReasonFound = this.exclusionReasons.find((exclusionReason) => exclusionReason.code === code);
        if (exclusionReasonFound) {
          reason = exclusionReasonFound.name;
        }
      }
      return reason;
    },
    async loadPlanChangeReasons() {
      this.planChangeReasonService.FindAll().then((response) => {
        if (response && response.data.length > 0) {
          this.planChangeReasons = response.data;
          this.planChangeReasons.sort((a, b) => (a.description > b.description ? 1 : -1));
        }
      });
    },
    capturePlanReasonCode(code) {
      let planReasonDescription = 'Carregando...';
      if (code && (this.planChangeReasons && this.planChangeReasons.length > 0)) {
        planReasonDescription = this.planChangeReasons.find((reason) => reason.code === code);
        return planReasonDescription.description;
      }
      return planReasonDescription;
    },
    redirectMovementCriticized() {
      if (this.isRHProtegido) {
        this.$router.push({ name: 'MovementDetail', query: { id: this.movementRecord.movementRecordParentId, freeMovement: this.isFreeMovement, isRHProtegido: `${this.isRHProtegido}` } }).then(() => { this.$router.go(); });
      } else {
        this.$router.push({ name: 'MovementDetail', query: { id: this.movementRecord.movementRecordParentId, freeMovement: this.isFreeMovement } }).then(() => { this.$router.go(); });
      }
    },
    async redirectMovementUpdated() {
      this.overlay = true;
      this.movementRecordService.GetMovementRecordByChangedFieldsByParentId(this.movementRecord.id).then((response) => {
        if (response && response.data) {
          this.dataResponse = response.data.id;
          this.overlay = false;
        } else {
          this.overlay = false;
        }
      }).catch(() => {
        this.overlay = false;
      });
    },
    verifyLogMovements() {
      if (this.movementRecord) {
        if (this.movementRecord.movementRecordParentId && this.returnCriticizedDate && this.movementRecord.updatedMovementDate) return false;
        if (this.movementRecord.movementRecordParentId === null || this.returnCriticizedDate === null || this.movementRecord.updatedMovementDate === null) return true;
      }
      return false;
    },
  },

  async created() {
    this.formatter = new Formatters();
    this.movementRecordService = new MovementRecordService();
    this.genderService = new GenderService();
    this.maritalStatusService = new MaritalStatusService();
    this.employmentRelationshipService = new EmploymentRelationshipService();
    this.bankService = new BankService();
    this.financialGroupService = new FinancialGroupService();
    this.contractService = new ContractService();
    this.contractEligibilityService = new ContractEligibilityService();
    this.contractProductService = new ContractProductService();
    this.product2Service = new Product2Service();
    this.rule = new Rule();
    this.cancelationReasonService = new CancelationReasonService();
    this.planChangeReasonService = new PlanChangeReasonService();
    this.beneficiaryContractedPlanService = new BeneficiaryContractedPlanService();
  },
};
</script>
